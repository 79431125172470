<template>
  <div class="div-padding" id="usecase">
    <b-container fluid class="p-5 mt-5 no-padding-mobile wrapper">
      <b-row class="text-center text-lg-left pl-3">
        <b-col lg="4">
          <div class="title pt-0">Open roles</div>
          <div class="text-center text-lg-left mb-5 pl-2">
            <div class="position-relative my-4">
              <ul
                class="nav-inverse margin-left-center-mobile no-style-bullet pl-2"
              >
                <li
                  v-for="(tabContent, index) in tabContents"
                  :key="`${index}`"
                  :id="`nav-tab-${index}`"
                  :data-id="`${index}`"
                  :class="`nav-item tab-bottom tab-text ${activeTabColor(
                    index
                  )}`"
                  @click="onTabClick"
                  @mouseenter="onTabMouseEnter"
                  @mouseleave="onTabMouseLeave"
                >
                  <div class="nav-link subtitle" href="#" :data-id="`${index}`">
                    {{ tabContent.tab }}
                  </div>
                </li>
              </ul>
              <div
                class="line"
                :style="{
                  left: `${lineStyles.left}px`,
                  width: `${lineStyles.width}px`,
                }"
              ></div>
            </div>
          </div>
        </b-col>
        <b-col lg="8">
          <b-row>
            <b-col md>
              <div
                class="descbox"
                v-html="tabContents[currentTab].jobdesc"
              ></div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
// .line {
//   position: absolute;
//   bottom: 0;
//   transition: left 0.5s ease-in-out, width 0.5s 0.1s;
//   background-color: #2d23a8;
//   left: 0;
//   width: 6px;
//   height: 60px;
//   pointer-events: none;
// }

.aboutrole {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  color: #4f4f4f;
}

.title {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 43px;
  @media screen and (max-width: 576px) {
    text-align: left;
  }
}

.subtitle {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  @media screen and (max-width: 576px) {
    text-align: left;
  }
}

.no-style-bullet {
  list-style-type: none;
  margin-left: -10%;
  //   @media screen and (max-width: 576px) {
  //     margin-left: -15%;
  //   }
}

.text-color-blue {
  color: #2d23a8 !important;
}

.tab-text {
  color: #bdbdbd;
}

.tab-bottom {
  border-left: 3px solid #c4c4c4 !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.descbox {
  background: #fefefe;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
  @media screen and (max-width: 576px) {
    text-align: left !important;
    margin-bottom: 10%;
  }
}
</style>

<script>
/* Vue Section */
// import TekkisButton from "./buttons/TekkisButton.vue";

export default {
  components: {
    // TekkisButton,
  },
  computed: {
    lineStyles() {
      return this.lineTemp == null ? this.line : this.lineTemp;
    },
    activeTabColor() {
      if (this.hoverTab !== null)
        return (tab) => (tab == this.hoverTab ? "text-color-blue" : "");

      return (tab) => (tab == this.currentTab ? "text-color-blue" : "");
    },
  },
  mounted() {
    this.updateLine(0);
  },
  data() {
    return {
      currentTab: 0,
      timer: "",
      hoverTab: null,
      line: {
        left: 0,
        width: 69,
      },
      lineTemp: null,
      tabContents: [
        {
          tab: "Senior Technology Associate",
          jobdesc:
            "<div style='font-family: Jost; font-style: normal; font-weight: 500; font-size: 24px; line-height: 35px; color: #4f4f4f;'>Basic qualifications</div><br />" +
            "Possess Degree in Computer Science / Computer Engineering / Information Technology.<br />OR<br /> At least 3 years involved in Software Development Life Cycle.<br />Payment gateway OR e-KYC experience will be an added advantage.<br /><br />" +
            "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;margin-top:0.5rem'>What you will do</div><br />" +
            "<p>As a Senior Technology Associate at tekkis, you will be tasked with :</p>" +
            "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
            "<li style='margin-bottom: 15px;'>Research, design, develop, and test our systems</li>" +
            "<li style='margin-bottom: 15px;'>Developing scalable backend microservices, creating based backend logic APls and frontend API integration</li>" +
            "<li style='margin-bottom: 15px;'>When all is done, you will be deploying them on cloud computing servers</li>" +
            "<li style='margin-bottom: 15px;'>Collaborating with other teams including Data Science and Designs as you will never walk alone here</li>" +
            "<li style='margin-bottom: 15px;'>Experience in problem solving skills, demonstrating good judgment in selecting best methods and techniques</li>" +
            "<li style='margin-bottom: 15px;'>Plan and execute deployment of system features and monitor for successful integration, maintaining the system throughout its lifecycle</li>" +
            "</ul>" +
            "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;margin-top:0.5rem'>What You Need To Have</div><br />" +
            "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
            "<li style='margin-bottom: 15px;'>Programming skills with: PHP, JavaScript (Ajax & JQuery)</li>" +
            "<li style='margin-bottom: 15px;'>Understanding of Codeigniter or any other MVC framework and RESTful API</li>" +
            "<li style='margin-bottom: 15px;'>Analyze, design, and develop IT solutions. Range of duties include designing, debugging, coding, documenting, building, testing and launching new features.</li>" +
            "<li style='margin-bottom: 15px;'>Experience in UAT and deploying systems to our cloud computing servers and databases for our new clients</li>" +
            "<li style='margin-bottom: 15px;'>Ability to use MySQL proficiently</li>" +
            "<li style='margin-bottom: 15px;'>Possess problem-solving skills and good communication skills.</li>" +
            "<li style='margin-bottom: 15px;'>Experience in ability to quickly learn new systems and business processes</li>" +
            "<li style='margin-bottom: 15px;'>Excellent organizational and time management skills</li>" +
            "<li style='margin-bottom: 15px;'>Self-driven, motivated, flexible, and innovative</li>" +
            "</ul>" +
            "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;margin-top:0.5rem'>What Is Nice To Have</div><br />" +
            "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
            "<li style='margin-bottom: 15px;'>Understanding of large-scale distributed systems in practice, including multi-tier architectures, application security, monitoring and storage systems.</li>" +
            "<li style='margin-bottom: 15px;'>Knowledge on message queue (Redis, ZeroMQ or RabbitMq)</li>" +
            "<li style='margin-bottom: 15px;'>Experience with source-controlled environment (e.g. Git)</li>" +
            "</ul>" +
            "<div class='text-center'><a href='https://www.linkedin.com/jobs/view/3317086730/' target='_blank' class='btn' style='margin:auto; color: #FEFEFE;font-family: Jost;font-style: normal;font-weight: 600;font-size: 18px;line-height: 26px;letter-spacing: 0.01em;background: #FF3377;box-shadow: 0px 0px 15px #FFD8D6;border-radius: 32.5px;'>Apply now</a></div>",
        },
        // {
        //   tab: "Risk Compliance Manager",
        //   jobdesc:
        //     "<div style='font-family: Jost; font-style: normal; font-weight: 600; font-size: 24px; line-height: 35px; color: #4f4f4f;'>About the role</div><br />" +
        //     "The tekkis team is looking for an experienced Risk & Compliance Manager to join our growing team. This position is responsible for establishing frameworks and managing processes required to comply with local financial regulations including Bank Negara and Securities Commission. A strong candidate for this role would have a solid understanding of Malaysian compliance and anti-money laundering (AML), Know Your Customer (KYC) and Payment Card Industry Data Security Standard (PCIDSS) requirements.<br /><br />" +
        //     "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;'>You will:</div><br />" +
        //     "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
        //     "<li style='margin-bottom: 15px;'>Lead the coordination of regulatory exams, audits, or internal business unit assessments to meet requirements set out by Bank Negara and Securities Commission</li>" +
        //     "<li style='margin-bottom: 15px;'>Interpret statutory and regulatory requirements to establish compliance frameworks particularly for KYC, AML/ CTF, sanctions, and financial crime regulations and issues</li>" +
        //     "<li style='margin-bottom: 15px;'>Regularly conduct compliance reviews and risk assessments of business areas to ensure systems are robust, effective, and not vulnerable to regulatory breaches or ineffective controls</li>" +
        //     "<li style='margin-bottom: 15px;'>Stay updated with regulatory changes to ensure ongoing compliance in meeting licensing requirements</li>" +
        //     "<li style='margin-bottom: 15px;'>Identify gaps or areas for improvement in current systems, processes, and policies, and work with cross-functional teams including legal, operations, and technology to implement changes or conduct system enhancements</li>" +
        //     "<li style='margin-bottom: 15px;'>Drive continuous improvement of processes, data analytics, and fraud system implementation</li></ul>" +
        //     "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;'>You have:</div><br />" +
        //     "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
        //     "<li style='margin-bottom: 15px;'>Experience in compliance, risk, or related roles requiring adherence to BNM guidelines</li>" +
        //     "<li style='margin-bottom: 15px;'>Good understanding of internal controls and risk management</li>" +
        //     "<li style='margin-bottom: 15px;'>Sound knowledge in Bank Negara and Securities Commission rules and regulations</li>" +
        //     "<li style='margin-bottom: 15px;'>Knowledge of Internal Controls including Audit Methodology, Compliance Program Design and Techniques</li>" +
        //     "<li style='margin-bottom: 15px;'>Strong analytical, communication, presentation skills and updated with current banking industry environment and changes</li></ul>" +
        //     "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;'>Nice to have:</div><br />" +
        //     "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
        //     "<li style='margin-bottom: 15px;'>Experience within payments, fintech, e-commerce, or financial services</li>" +
        //     "<li style='margin-bottom: 15px;'>Relevant qualifications such as Certification in Regulatory Compliance and Certification and Advanced Certification in AML/CFT</li></ul><br /><br />" +
        //     "<div class='text-center'><a href='https://www.linkedin.com/jobs/view/2882212261/' target='_blank' class='btn' style='margin:auto; color: #FEFEFE;font-family: Jost;font-style: normal;font-weight: 600;font-size: 18px;line-height: 26px;letter-spacing: 0.01em;background: #FF3377;box-shadow: 0px 0px 15px #FFD8D6;border-radius: 32.5px;'>Apply now</a></div>",
        // },
        // {
        //   tab: "DevOps Engineer",
        //   jobdesc:
        //     "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;'>You will:</div><br />" +
        //     "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
        //     "<li style='margin-bottom: 15px;'>Maintain and improve our infrastructure</li>" +
        //     "<li style='margin-bottom: 15px;'>Ensure excellent security, resilience and uptime</li>" +
        //     "<li style='margin-bottom: 15px;'>Support increased automation of system rollout to our data centres</li>" +
        //     "<li style='margin-bottom: 15px;'>Responsible for onsite system deployment</li>" +
        //     "<li style='margin-bottom: 15px;'>Responsible for troubleshooting, consolidating issue log, and reporting</li>" +
        //     "<li style='margin-bottom: 15px;'>Responsible for routine system and database maintenance, monitoring and ensure the system operation stability</li></ul>" +
        //     "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;'>You have:</div><br />" +
        //     "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
        //     "<li style='margin-bottom: 15px;'>Experience in compliance, risk, or related roles requiring adherence to BNM guidelines</li>" +
        //     "<li style='margin-bottom: 15px;'>Good understanding of internal controls and risk management</li>" +
        //     "<li style='margin-bottom: 15px;'>Sound knowledge in Bank Negara and Securities Commission rules and regulations</li>" +
        //     "<li style='margin-bottom: 15px;'>Knowledge of Internal Controls including Audit Methodology, Compliance Program Design and Techniques</li>" +
        //     "<li style='margin-bottom: 15px;'>Strong analytical, communication, presentation skills and updated with current banking industry environment and changes</li></ul><br /><br />" +
        //     "<div class='text-center'><a href='https://www.linkedin.com/jobs/view/2884166974/' target='_blank' class='btn' style='margin:auto; color: #FEFEFE;font-family: Jost;font-style: normal;font-weight: 600;font-size: 18px;line-height: 26px;letter-spacing: 0.01em;background: #FF3377;box-shadow: 0px 0px 15px #FFD8D6;border-radius: 32.5px;'>Apply now</a></div>",
        // },
        // {
        //   tab: "Digital Marketing Specialist",
        //   jobdesc:
        //     "<div style='font-family: Jost; font-style: normal; font-weight: 600; font-size: 24px; line-height: 35px; color: #4f4f4f;'>About the role</div><br />" +
        //     "Your role would include market research and targeting, social media outreach, search engine optimization, newsletters, lead generation, brand building, and conversion. <br /><br />" +
        //     "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;'>You will:</div><br />" +
        //     "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
        //     "<li style='margin-bottom: 15px;'>Create and implement marketing strategies, campaigns, and communications to support the introduction of new initiatives or solutions </li>" +
        //     "<li style='margin-bottom: 15px;'>Develop, oversee and implement omnichannel marketing and content plans with measurable KPIs on digital channels; together with the junior marketer</li>" +
        //     "<li style='margin-bottom: 15px;'>Publish content that resonates with the target user and fits into the channel, campaign, and content goals and timeline</li>" +
        //     "<li style='margin-bottom: 15px;'>Optimize content for Search Engine (SEO), Social Media (SMO) and Email, in line with best practices, user trends, and algorithm updates</li>" +
        //     "<li style='margin-bottom: 15px;'>Understand and optimize marketing funnels to improve lead generation & conversions</li>" +
        //     "<li style='margin-bottom: 15px;'>Share weekly marketing reports on channel and campaign performance</li>" +
        //     "<li style='margin-bottom: 15px;'>Liaise with freelancers, media, or agencies to support marketing initiatives like product launches and press releases  </li>" +
        //     "<li style='margin-bottom: 15px;'>Work on a hybrid model (some days at home, some days in the office)</li></ul>" +
        //     "<div style='font-family: Jost;font-style: normal;font-weight: 500;font-size: 24px;line-height: 35px;color: #4F4F4F;'>You have:</div><br />" +
        //     "<ul style='font-family: Jost;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #4F4F4F;'>" +
        //     "<li style='margin-bottom: 15px;'>Min. 4 years experience working in Digital Marketing or similar; lead generation, or ROI-demand marketing experience is a plus</li>" +
        //     "<li style='margin-bottom: 15px;'>Experience developing junior digital marketers </li>" +
        //     "<li style='margin-bottom: 15px;'>Knowledge of SEO, SMO, and/ or Email Marketing</li>" +
        //     "<li style='margin-bottom: 15px;'>Digital marketing technology literacy, including a high degree of competency with marketing automation and/or SEM</li>" +
        //     "<li style='margin-bottom: 15px;'>Experience measuring performance using dashboards, and drawing insights for further optimization, using analytics tools such as Google Analytics, etc.</li>" +
        //     "<li style='margin-bottom: 15px;'>Ability to work independently and collaboratively as part of a team in a fast-paced startup environment</li></ul><br /><br />" +
        //     "<div class='text-center'><a href='https://my.hiredly.com/jobs/jobs-malaysia-tekkis-job-digital-marketing-specialist-0' target='_blank' class='btn' style='margin:auto; color: #FEFEFE;font-family: Jost;font-style: normal;font-weight: 600;font-size: 18px;line-height: 26px;letter-spacing: 0.01em;background: #FF3377;box-shadow: 0px 0px 15px #FFD8D6;border-radius: 32.5px;'>Apply now</a></div>",
        // },
      ],
    };
  },
  created() {
    // this.timer = setInterval(this.refreshTab, 5000);
  },
  methods: {
    refreshTab() {
      if (this.currentTab == this.tabContents.length - 1) {
        this.currentTab = 0;
        this.updateLine(this.currentTab);
        return;
      }

      this.currentTab = parseInt(this.currentTab) + 1;
      this.updateLine(this.currentTab);
    },
    updateLine(tabNumber) {
      const tab = document.getElementById("nav-tab-" + tabNumber);
      if (tab) {
        let bottomLineStyle = {
          left: tab.offsetLeft,
          width: tab.clientWidth,
        };
        this.line = bottomLineStyle;
      }
    },
    onTabClick(evt) {
      const tab = evt.target;
      this.currentTab = tab.dataset.id;
      let bottomLineStyle = {
        left: tab.offsetLeft,
        width: tab.clientWidth,
      };
      this.line = bottomLineStyle;
    },
    onTabMouseEnter(evt) {
      const tab = evt.target;
      let bottomLineStyle = {
        left: tab.offsetLeft,
        width: tab.clientWidth,
      };
      this.lineTemp = bottomLineStyle;
      this.hoverTab = tab.dataset.id;
    },
    onTabMouseLeave() {
      this.lineTemp = null;
      this.hoverTab = null;
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
