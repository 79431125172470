<template>
  <div class="career">
    <CareerDescription />
    <CareerBenefits />
    <CareerRoles />
    <GetStarted />
  </div>
</template>

<script>
import CareerDescription from "../components/CareerDescription.vue";
import CareerBenefits from "../components/CareerBenefits.vue";
import CareerRoles from "../components/CareerRoles.vue";
import GetStarted from "../components/GetStarted";

export default {
  name: "career",
  components: {
    CareerDescription,
    CareerBenefits,
    CareerRoles,
    GetStarted,
  },
  data() {
    return {
      title: "Tekkis - Career",
      description:
        "Building SEA’s first unified digital payment and identity verification platform with us! Follow us on LinkedIn, Facebook, or Instagram for updates on new open positions, news and product updates.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Building SEA’s first unified digital payment and identity verification platform with us! Follow us on LinkedIn, Facebook, or Instagram for updates on new open positions, news and product updates.";
    },
  },
};
</script>
